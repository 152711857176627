import React from "react";
import PropTypes from "prop-types";
import { url } from "../../../config/website";

export function WebPageJsonLD({
  title,
  imagePublicURL,
  slug,
  description,
  breadcrumbs,
  date,
  modified,
  imageWidth,
  imageHeight,
}) {
  const webPageData = {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": `${url}${slug}`,
      breadcrumb: breadcrumbs
        ? {
            "@type": "BreadcrumbList",
            itemListElement: breadcrumbs.map(({ position, name, item }) => ({
              "@type": "ListItem",
              position: position,
              name: name,
              item: item,
            })),
          }
        : null,
    },

    headline: title,
    description: description,
    url: `${url}${slug}`,
    ...(date && { datePublished: date }),
    ...(modified && { dateModified: modified }),
    image: {
      "@type": "ImageObject",
      url: imagePublicURL
        ? `${url}${imagePublicURL}`
        : `${url}/paleta-de-colores.png`,
      width: imageWidth ? imageWidth : 1000,
      height: imageHeight ? imageHeight : 1500,
    },

    publisher: {
      "@type": "Organization",
      name: "Paletadecolores.online",
      logo: {
        "@type": "ImageObject",
        url: `${url}/paletadecolores-online-logo.png`,
        height: 200,
        width: 200,
      },
    },
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(webPageData) }}
    />
  );
}

WebPageJsonLD.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  imagePublicURL: PropTypes.string,
  slug: PropTypes.string,
  breadcrumb: PropTypes.array,
};

export default WebPageJsonLD;

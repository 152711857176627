import { url } from "../../config/website";

function slugBreadCrumb(arrSlug, i, item, locale) {
  if (locale === "es") {
    switch (i) {
      case 3:
        return `/${arrSlug[i - 3]}/${arrSlug[i - 2]}/${
          arrSlug[i - 1]
        }/${item}/`;
      case 2:
        return `/${arrSlug[i - 2]}/${arrSlug[i - 1]}/${item}/`;
      case 1:
        return `/${arrSlug[i - 1]}/${item}/`;
      case 0:
        return `/${item}/`;
      default:
        break;
    }
  }

  if (locale === "pt") {
    switch (i) {
      case 2:
        return `/pt/${arrSlug[i - 2]}/${arrSlug[i - 1]}/${item}/`;
      case 1:
        return `/pt/${arrSlug[i - 1]}/${item}/`;
      case 0:
        return `/pt/${item}/`;
      default:
        break;
    }
  }

  if (locale === "en") {
    switch (i) {
      case 2:
        return `/en/${arrSlug[i - 2]}/${arrSlug[i - 1]}/${item}/`;
      case 1:
        return `/en/${arrSlug[i - 1]}/${item}/`;
      case 0:
        return `/en/${item}/`;
      default:
        break;
    }
  }

  if (locale === "fr") {
    switch (i) {
      case 2:
        return `/fr/${arrSlug[i - 2]}/${arrSlug[i - 1]}/${item}/`;
      case 1:
        return `/fr/${arrSlug[i - 1]}/${item}/`;
      case 0:
        return `/fr/${item}/`;
      default:
        break;
    }
  }
}

export function transformSlugToArrBreadcrumbs(slug, locale) {
  const arrSlug = slug.split("/");
  arrSlug.shift();
  arrSlug.pop();

  if (locale === "es") {
    const newArrSlug = arrSlug.map((item, i) => ({
      position: i + 2,
      name: item.includes("-") ? item.replace(/-/g, " ") : item,
      item: `${url}${slugBreadCrumb(arrSlug, i, item, locale)}`,
      slug: slugBreadCrumb(arrSlug, i, item, locale),
    }));
    newArrSlug.splice(0, 0, {
      position: 1,
      name: "paleta de colores",
      item: url,
      slug: "/",
    });
    return newArrSlug;
  }

  if (locale === "pt") {
    // Solo si el idioma es otro se elimina el primer elemento del slug que seria /en/
    arrSlug.shift();

    const newArrSlug = arrSlug.map((item, i) => ({
      position: i + 2,
      name: item.includes("-") ? item.replace(/-/g, " ") : item,
      item: `${url}${slugBreadCrumb(arrSlug, i, item, locale)}`,
      slug: slugBreadCrumb(arrSlug, i, item, locale),
    }));

    // Cambiar el primer elemento del breadcrumb dependiendo del idioma

    newArrSlug.splice(0, 0, {
      position: 1,
      name: "paleta de cores",
      item: `${url}/pt/`,
      slug: "/pt/",
    });

    return newArrSlug;
  }

  if (locale === "en") {
    // Solo si el idioma es otro se elimina el primer elemento del slug que seria /en/
    arrSlug.shift();

    const newArrSlug = arrSlug.map((item, i) => ({
      position: i + 2,
      name: item.includes("-") ? item.replace(/-/g, " ") : item,
      item: `${url}${slugBreadCrumb(arrSlug, i, item, locale)}`,
      slug: slugBreadCrumb(arrSlug, i, item, locale),
    }));

    // Cambiar el primer elemento del breadcrumb dependiendo del idioma

    newArrSlug.splice(0, 0, {
      position: 1,
      name: "color palettes",
      item: `${url}/en/`,
      slug: "/en/",
    });

    return newArrSlug;
  }
  if (locale === "fr") {
    // Solo si el idioma es otro se elimina el primer elemento del slug que seria /en/
    arrSlug.shift();

    const newArrSlug = arrSlug.map((item, i) => ({
      position: i + 2,
      name: item.includes("-") ? item.replace(/-/g, " ") : item,
      item: `${url}${slugBreadCrumb(arrSlug, i, item, locale)}`,
      slug: slugBreadCrumb(arrSlug, i, item, locale),
    }));

    // Cambiar el primer elemento del breadcrumb dependiendo del idioma

    newArrSlug.splice(0, 0, {
      position: 1,
      name: "palette des couleurs",
      item: `${url}/fr/`,
      slug: "/fr/",
    });

    return newArrSlug;
  }
}

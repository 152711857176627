module.exports = {
  pathPrefix: "/",
  title: "Paleta de colores",
  titleAlt: "Paleta de colores",
  description: `☝ Encuentra la Paleta de Color ideal para tu Proyecto  ✅. con nuestro Generador de Paletas de Colores Fácil y rapido 🚀. `,
  url: "https://paletadecolores.online",
  siteLanguage: "es",
  Image: "paleta-de-colores.png",
  logoImage: "logo-paletas-de-colores-online.png",
  ImageAlt: "Paleta de color online",
  ogLanguage: "es",

  //JSONLD / Manifest
  favicon: "src/images/paletadecolores-online-logo.png",
  shortName: "Paletadecolores",
  author: "Juneiker",
  themeColor: "#2962ff",
  backgroundColor: "#EBEDF2",
  twitter: "@juneikerc3",
  facebook: "juneikerc",
};

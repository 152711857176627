import React from "react";
import PropTypes from "prop-types";
import { url } from "../../../config/website";

export function BasicHeadTags({
  title,
  description,
  noindex,
  nofollow,
  canonicalUrl,
  type,
  image = "",
  slug,
}) {
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description} />

      {noindex || nofollow ? (
        <meta
          name="robots"
          content={`${noindex ? "noindex" : "index"}, ${
            nofollow ? "nofollow" : "follow"
          }`}
        />
      ) : null}
      {noindex || nofollow ? (
        <meta
          name="googlebot"
          content={`${noindex ? "noindex" : "index"}, ${
            nofollow ? "nofollow" : "follow"
          }`}
        />
      ) : null}

      {canonicalUrl ? <link rel="canonical" href={canonicalUrl} /> : null}
      <meta property="og:title" content={title} />
      {description ? (
        <meta property="og:description" content={description} />
      ) : null}
      {type ? <meta property="og:type" content={type} /> : null}
      {image ? <meta property="og:image" content={image} /> : null}
      <meta property="og:url" content={slug ? `${url}${slug}` : url} />
    </>
  );
}

BasicHeadTags.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  noindex: PropTypes.bool,
  nofollow: PropTypes.bool,
  canonicalUrl: PropTypes.string,
};

import React from "react";
import { graphql } from "gatsby";
import { content_wrapper } from "../styles/modules/content.module.css";
import Layout from "../components/layout";
import { BasicHeadTags } from "../components/SEO/BasicHeadTags";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { transformSlugToArrBreadcrumbs } from "../helpers/transformSlugToArr";
import BreadCrumbs from "../components/BreadCrumps";
import WebPageJsonLD from "../components/SEO/WebPageJsonLD";

function TematicPost({ data }) {
  const { mdx } = data;
  const breadCrumbsList = transformSlugToArrBreadcrumbs(
    mdx.frontmatter.slug,
    mdx.frontmatter.locale
  );
  return (
    <Layout locale={mdx.frontmatter.locale}>
      <BreadCrumbs breadCrumbsList={breadCrumbsList} />
      <article className={content_wrapper}>
        <h1>{mdx.frontmatter.title}</h1>
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </article>
    </Layout>
  );
}

export const Head = ({ data }) => {
  const { mdx } = data;

  const breadCrumbsList = transformSlugToArrBreadcrumbs(
    mdx.frontmatter.slug,
    mdx.frontmatter.locale
  );

  return (
    <>
      <BasicHeadTags
        title={mdx.frontmatter.metaTitle}
        description={mdx.frontmatter.metaDescription}
      />

      <WebPageJsonLD
        title={mdx.frontmatter.metaTitle}
        slug={mdx.frontmatter.slug}
        description={mdx.frontmatter.metaDescription}
        breadcrumbs={breadCrumbsList}
        date={mdx.frontmatter.date}
        modified={mdx.frontmatter.modified}
      />
    </>
  );
};

export const query = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      body
      id
      frontmatter {
        title
        metaTitle
        metaDescription
        slug
        locale
        date
        modified
      }
    }
  }
`;

export default TematicPost;
